// Variables
@import '../variables';

input:focus ~ .floating-label,
input:not(:focus):valid ~ .floating-label{
    top: 8px;
    bottom: 10px;
    left: 15px;
    font-size: 11px;
    opacity: 1;
    color: $color-primary
}

.inputText {
    font-size: 14px;
    width: 343px;
    height: 56px;
    border-radius: 8px;
}

.floating-label {
    color: $color-gray;
    position: absolute;
    pointer-events: none;
    left: 20px;
    top: 18px;
    font-size: 14px;
    transition: 0.2s ease all;
}
.box-login {
    border-color: white;
    border-radius: 12px;
    min-height: 65vh;
    max-height: 100vh;
    margin: 3vh 0;
    &__logo {
        padding-top: 15px;
        padding-bottom: 15px;
        text-align: center;
       img {
           max-height: 40px;
       }
        .qr-code {
            max-height: 150px;
        }

    }
    .form {
        text-align: center;
        width: 350px;
        .box-input {
            &:after {
                display: block;
                content: "";
                border-bottom: 2px solid $color-primary;
                -webkit-transform: scaleX(0);
                transform: scaleX(0);
                -webkit-transition: -webkit-transform .35s ease-in-out;
                transition: -webkit-transform .35s ease-in-out;
                transition: transform .35s ease-in-out;
                transition: transform .35s ease-in-out,-webkit-transform .35s ease-in-out;
                width: 95%;
                margin: auto;
                border-radius: 8px;
            }
            &:focus-within::after{
                transform: scaleX(1);
            }

        }
        &__input {
            ppearance: none;
            width: 100%;
            border: 0;
            font-family: inherit;
            padding: 16px 12px 0;
            height: 56px;
            background: #f4f6fa;
            transition: all .15s ease;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: #25282b;
            &:focus {
                outline: none;
            }
        }
        .btn-login {
            background-color: $color-primary;
            color: white;
            width: 100%;
            height: 44px;
        }

    }
    .form-otp {
        .ap-otp-inputs {
            .ap-otp-input {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                border: 0;
                width: 30px;
                height: 34px;
                margin: 4px;
                text-align: center;
                font-size: 18px;
                background: #f4f6fa;
                border-radius: 4px;
                font-family: inherit;
                transition: all .15s ease;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                color: #25282b;
            }
        }
        .btn-action {
            .btn-cancel {
                color: #15ae62;
                background-color: #f4f6fa;
                width: 50%;
                height: 44px;
            }
            .btn-confirm {
                background-color: $color-primary;
                color: white;
                width: 50%;
                height: 44px;
            }
        }
    }

}

.btn-logout {
    background: transparent;
    border: none;
    text-decoration: none;
    color: black;
    padding-top: 4px;
    font-weight: 500;

}

// RESPONSIVE
@include Responsive(XS) {
    .header {
        width: 100%;
        //padding: 0;
        //margin: 0;
    }
    .box-content {
        padding: 0;
        .box-login {
            max-height: 100vh;
        }
    }

}

@include Responsive(SM) {
    .box-login {
        min-height: 65vh !important;
    }

    .form-otp {
        .ap-otp-inputs {
            .ap-otp-input {
                width: 50px !important;
                height: 56px !important;
            }
        }
    }
}

