// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;
$color-primary: #15ae62;
$color-gray: #A0A4A8;
    // Khai báo kích thước tương ứng với màn hình thiết bị

$XS:     320px;
$SM:     768px;
$MD:    1024px;
$XL:   1170px;

// media queries

@mixin Responsive($canvas) {
    @if $canvas == XS {
        @media only screen and (min-width: $XS) { @content; }
    }
    @else if $canvas == SM {
        @media only screen and (min-width: $SM) { @content; }
    }
    @else if $canvas == MD {
        @media only screen and (min-width: $MD) { @content; }
    }
    @else if $canvas == XL {
        @media only screen and (min-width: $XL) { @content; }
    }
}
